$primary: hsl(40, 40%, 54%);//#B9995A
$info: hsl(196, 100%, 31%);//#00739D
$success: hsl(159, 100%, 39%);//#00C781
$warning: hsl(38, 100%, 54%);//#FFAA15
$danger: hsl(0, 100%, 62%); //#FF403F

$link: $primary;
$family-primary: 'Open Sans', sans-serif;
$black : hsl(0, 0%, 12%);//#1f1f1f

$border-color : #E8E8E8;
$border-color-darker: #CBCBCB;

$extra-bold : 800;

$secondary: #445777;
$secondary-bis: #697992;
$secondary-ter: #8F9AAD;
$secondary-light: #B4BCC9;
$secondary-lighter: #F0F2F4;

$tooltip-background-opacity: 1;
$tooltip-font-size: 14px;
$tooltip-padding: 5px 10px;
$tooltip-radius: 4px;
